import React, { Suspense, useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import TagManager from 'react-gtm-module';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { withLDConsumer, withLDProvider } from 'launchdarkly-react-client-sdk';

import * as messageActions from './actions/messages';
import reducerRegistry from './reducers/reducerRegistry';
import ggDASHConfig from './ggDASHConfig';

import MessageBanner from './components/MessageBanner';
import NavigationBar from './components/NavigationBar';
import Loading from './components/Loading';

import Home from './screens/Home';
import Login from './screens/Login';
import Logout from './screens/logout';
import Help from './screens/Help';
import Notes from './screens/Notes';
import NotFound from './screens/NotFound';
import ProtectedRoute from './components/ProtectedRoute';
import SSO from './screens/SSO';
import ResetPassword from './screens/ResetPassword';
import { SET_SELECTED_ACCOUNT } from './actions/types';
import getLDClientId from './lib/getLDClientId';
import '@gogoair/ota/dist/style.css';

/** new version of connnectivity insights app  */
const ConnectivityInsights = React.lazy(() => import('@gogoair/connectivity-insights'));
const ConnectivityInsightsBeta = React.lazy(() => import('connectivity-insights-beta'));
const Admin = React.lazy(() => import('@gogoair/admin'));
const Reporting = React.lazy(() => import('@gogoair/reporting'));
const Ssa = React.lazy(() => import('@gogoair/ssa'));
const Billing = React.lazy(() => import('@gogoair/dash-billing'));
const Mix = React.lazy(() => import('@gogoair/mixui'));
const Activations = React.lazy(() => import('@gogoair/activations'));
const Ota = React.lazy(() => import('@gogoair/ota'));
const ServiceRequest = React.lazy(() => import('@gogoair/service_request'));
const ServiceRequestBeta = React.lazy(() => import('service-request-beta'));
const ActivationsBeta = React.lazy(() => import('activations-beta'));
const CustomerOnboardingBeta = React.lazy(() => import('customer-onboarding-beta'));
const SsaBeta = React.lazy(() => import('ssa-beta'));
const CustomerOnboarding = React.lazy(() => import('@gogoair/customer-onboarding'));
const AdminBeta = React.lazy(() => import('admin-beta'));

const APP_TITLE = 'Gogo DASH';

if (ggDASHConfig.deployedEnv === 'production') {
  const tagManagerArgs = {
    gtmId: 'GTM-N9ZHLL8',
  };
  TagManager.initialize(tagManagerArgs);
} else {
  const tagManagerArgs = {
    gtmId: 'GTM-55X5GHS',
  };
  TagManager.initialize(tagManagerArgs);
}

const GET_RELEASE_NOTES = gql`
  query getReleaseNotes {
    notes @rest(path: "api/v1/systemnotifications", type: "[Note]") {
      message
      from
      to
    }
  }
`;

const setMessage = (actions, message) => {
  if (message && message.notes) {
    const results = message.notes.reduce((acc, sm) => {
      if (sm && sm.from < Date.now() && sm.to > Date.now()) {
        sessionStorage.setItem('notificationSent', 'true');
        acc.push(sm.message);
      }
      return acc;
    }, []);
    actions.setMessage({ type: 'systemMessage', message: results, icon: 'fa-comment' });
  }
};

const App = props => {
  const { history, location, actions, client, selectedAccountId, ldClient, user, flags } = props;
  const { data } = useQuery(GET_RELEASE_NOTES, {
    skip: sessionStorage.getItem('notificationSent') === 'true',
  });

  const activationsFlag = flags.activations;
  const noActivations = activationsFlag === 4;
  const allowedActivations2 = (activationsFlag === 2 || activationsFlag === 3) && !noActivations;
  const onlyAllowedActivations2 = activationsFlag === 2 && !noActivations;

  // These remaining flags are boolean
  const billingFlag = flags.billing;
  const connectivityInsightsFlag = flags?.connectivityInsights;
  // @NOTE: there's an odd legacy flag with LD
  const mixFlag = Boolean(flags.mix);
  const ssaFlag = flags.ssa;
  const userManagementFlag = flags.userManagement;
  const maintenanceFlag = flags.ota;
  const serviceRequestFlag = flags.serviceRequest;
  const customerOnboardingFlag = flags.customerOnboarding;
  const customerOnboardingBetaFlag = flags.customerOnboardingBeta;
  const serviceRequestBetaFlag = flags.b41532RmaFormChange;
  const connectivityInsightsBetaFlag = flags.connectivityInsightsBeta;
  const ssaBetaFlag = flags.ssaBeta;
  const activationsBetaFlag = flags.activationsBeta;
  const userManagementBetaFlag = flags.userManagementBeta;
  const cobUnverified = user?.cob_unverified && user?.allAccountIds?.length === 0;

  useEffect(() => {
    if (cobUnverified && !window.location.pathname.includes('customer-onboarding')) {
      history.push('/customer-onboarding');
    }
  }, [cobUnverified, history]);

  useEffect(() => {
    const dataLayer = window.dataLayer || [];

    if (user?.token) {
      dataLayer.push({
        event: 'user-init',
        userId: user.token,
        userIsInternal: user?.isInternal ?? false,
      });
      if (window.ga) {
        window.ga('set', 'userId', user.token);
      }
    }

    if (ldClient) {
      const ldUser = {
        key: user?.token ?? 'anon',
        custom: { isInternal: user?.isInternal ?? false },
      };
      ldClient.identify(ldUser, undefined, () => {});
    }
  }, [ldClient, user]);

  useEffect(() => {
    if (document.title !== APP_TITLE) {
      document.title = APP_TITLE;
    }

    setMessage(actions, data);
  }, [actions, data]);

  return (
    Object.keys(flags).length !== 0 && (
      <div className="app">
        <NavigationBar location={location} />
        <MessageBanner {...props} />
        <Suspense fallback={<Loading />}>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/forgotpassword" component={Login} />
            <Route exact path="/resetpassword/:userID" component={ResetPassword} />
            <Route exact path="/logout" component={Logout} />

            {connectivityInsightsFlag && !connectivityInsightsBetaFlag && (
              <ProtectedRoute
                path="/dashboard"
                render={renderProps => (
                  <ConnectivityInsights
                    {...renderProps}
                    reducerRegistry={reducerRegistry}
                    history={history}
                    client={client}
                    user={user}
                    ldClient={ldClient}
                  />
                )}
              />
            )}
            {connectivityInsightsFlag && connectivityInsightsBetaFlag && (
              <ProtectedRoute
                path="/dashboard"
                render={renderProps => (
                  <ConnectivityInsightsBeta
                    {...renderProps}
                    reducerRegistry={reducerRegistry}
                    history={history}
                    client={client}
                    user={user}
                    ldClient={ldClient}
                  />
                )}
              />
            )}
            {ssaFlag && !ssaBetaFlag && (
              <ProtectedRoute
                path="/ssa"
                render={renderProps => (
                  <Ssa
                    {...renderProps}
                    reducerRegistry={reducerRegistry}
                    history={history}
                    client={client}
                    user={user}
                    ldClient={ldClient}
                  />
                )}
              />
            )}
            {ssaFlag && ssaBetaFlag && (
              <ProtectedRoute
                path="/ssa"
                render={renderProps => (
                  <SsaBeta
                    {...renderProps}
                    reducerRegistry={reducerRegistry}
                    history={history}
                    client={client}
                    user={user}
                    ldClient={ldClient}
                  />
                )}
              />
            )}
            {userManagementFlag && !userManagementBetaFlag && (
              <ProtectedRoute
                path="/admin"
                render={renderProps => (
                  <Admin
                    {...renderProps}
                    reducerRegistry={reducerRegistry}
                    history={history}
                    client={client}
                    user={user}
                    ldClient={ldClient}
                  />
                )}
              />
            )}
            {userManagementFlag && userManagementBetaFlag && (
              <ProtectedRoute
                path="/admin"
                render={renderProps => (
                  <AdminBeta
                    {...renderProps}
                    reducerRegistry={reducerRegistry}
                    history={history}
                    client={client}
                    user={user}
                    ldClient={ldClient}
                  />
                )}
              />
            )}
            <ProtectedRoute
              path="/reporting"
              render={renderProps => (
                <Reporting
                  {...renderProps}
                  reducerRegistry={reducerRegistry}
                  history={history}
                  client={client}
                />
              )}
            />
            {allowedActivations2 && !activationsBetaFlag && (
              <ProtectedRoute
                path="/activations"
                render={() => (
                  <Activations
                    selectedAccountId={selectedAccountId}
                    setSelectedAccountId={actions.setSelectedAccountId}
                    user={user}
                    ldClient={ldClient}
                  />
                )}
              />
            )}
            {allowedActivations2 && activationsBetaFlag && (
              <ProtectedRoute
                path="/activations"
                render={() => (
                  <ActivationsBeta
                    selectedAccountId={selectedAccountId}
                    setSelectedAccountId={actions.setSelectedAccountId}
                    user={user}
                    ldClient={ldClient}
                  />
                )}
              />
            )}
            {onlyAllowedActivations2 && <Redirect from="/activation" to="/activations" />}
            {billingFlag && (
              <ProtectedRoute
                path="/billing"
                render={renderProps => (
                  <Billing
                    {...renderProps}
                    reducerRegistry={reducerRegistry}
                    history={history}
                    client={client}
                  />
                )}
              />
            )}
            {mixFlag && (
              <ProtectedRoute
                path="/mix"
                render={renderProps => (
                  <Mix {...renderProps} reducerRegistry={reducerRegistry} history={history} />
                )}
              />
            )}
            {maintenanceFlag && (
              <ProtectedRoute
                path="/ota"
                render={() => (
                  <Ota
                    selectedAccountId={selectedAccountId}
                    setSelectedAccountId={actions.setSelectedAccountId}
                    user={user}
                    ldClient={ldClient}
                  />
                )}
              />
            )}
            {serviceRequestFlag && !serviceRequestBetaFlag && (
              <ProtectedRoute
                path="/service-request"
                render={() => (
                  <ServiceRequest
                    selectedAccountId={selectedAccountId}
                    setSelectedAccountId={actions.setSelectedAccountId}
                    user={user}
                    ldClient={ldClient}
                  />
                )}
              />
            )}
            {serviceRequestFlag && serviceRequestBetaFlag && (
              <ProtectedRoute
                path="/service-request"
                render={() => (
                  <ServiceRequestBeta
                    selectedAccountId={selectedAccountId}
                    setSelectedAccountId={actions.setSelectedAccountId}
                    user={user}
                    ldClient={ldClient}
                  />
                )}
              />
            )}
            {customerOnboardingFlag && !customerOnboardingBetaFlag && (
              <Route
                path="/customer-onboarding"
                render={() => (
                  <CustomerOnboarding
                    selectedAccountId={selectedAccountId}
                    setSelectedAccountId={actions.setSelectedAccountId}
                    user={user}
                    ldClient={ldClient}
                  />
                )}
              />
            )}
            {customerOnboardingFlag && customerOnboardingBetaFlag && (
              <Route
                path="/customer-onboarding"
                render={() => (
                  <CustomerOnboardingBeta
                    selectedAccountId={selectedAccountId}
                    setSelectedAccountId={actions.setSelectedAccountId}
                    user={user}
                    ldClient={ldClient}
                  />
                )}
              />
            )}
            <ProtectedRoute exact path="/SSO" component={SSO} />
            <Route exact path="/help" component={Help} />
            <Route exact path="/notes" component={Notes} />
            <Route component={NotFound} />
          </Switch>
        </Suspense>
      </div>
    )
  );
};

/* istanbul ignore next */
const mapStateToProps = state => ({
  message: state.global.pageMessage,
  selectedAccountId: state.global.selectedAccountId,
  user: state.global.userDetails,
});

/* istanbul ignore next */
const mapDispatchToProps = dispatch => ({
  actions: {
    setSelectedAccountId: (accountId, appKey) =>
      dispatch({
        type: SET_SELECTED_ACCOUNT,
        payload: {
          accountId,
          selectedApp: appKey,
        },
      }),
    messageClickHandler: () => dispatch(messageActions.clearMessage()),
    setMessage: response => dispatch(messageActions.setMessage(response)),
  },
});

const ConnectedApp = connect(mapStateToProps, mapDispatchToProps)(App);

const AppWithFlags = withLDConsumer()(ConnectedApp);

export default withLDProvider({
  clientSideID: getLDClientId(ggDASHConfig.deployedEnv),
  options: {},
})(AppWithFlags);

// export default ConnectedApp;
